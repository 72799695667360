import React, { useEffect, useState } from "react";
import { Form, Button, Toast, ToastContainer } from "react-bootstrap";
import TelegramLoginButton from "telegram-login-button";

import "./App.css";

const App = () => {
  const [telegramId, setTelegramId] = useState("");
  const [rawTelegram, setRawTelegram] = useState("");
  const [inputUsername, setInputUsername] = useState("");
  const [inputBuy, setInputBuy] = useState("");
  const [inputSell, setInputSell] = useState("");

  useEffect(() => {
    const telegram = localStorage.getItem("telegramId");
    const raw = localStorage.getItem("raw");
    if (telegram) {
      setTelegramId(telegram);
      setRawTelegram(raw);
    }
  }, []);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendTelegramMessage = async (data) => {
    const token = process.env.REACT_APP_botToken;
    const chat_id = process.env.REACT_APP_chatId;
    const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${data}&parse_mode=html`;

    fetch(url)
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => console.log("error", error));
  };

  const createNewTask = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", process.env.REACT_APP_clickUpApiKey);

    const raw = JSON.stringify({
      name: inputUsername,
      status: "New",
      custom_fields: [
        {
          id: "7f50faab-6de0-4cce-8227-5d645f070a47", // buy price
          value: inputBuy,
        },
        {
          id: "efd09879-1fe3-46c3-8117-ceacbfff0964", // sell price
          value: inputSell,
        },
        {
          id: "5fa6ebf9-09f5-4cfa-8fee-a0dd694f08b3", //telegra
          value: `https://web.telegram.org/a/#${telegramId}`,
        },
        {
          id: "b006f8c8-9162-4912-b691-5756c1aaec3c", //telegra
          value: rawTelegram,
        },
      ],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.clickup.com/api/v2/list/901002880155/task",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const { id } = result;
        const taskUrl = `https://app.clickup.com/t/${id}`;
        const message = `Заявка додана успішно! ${taskUrl}`;
        sendTelegramMessage(message);
        setShow(true);
        setInputUsername("");
        setInputBuy("");
        setInputSell("");
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await createNewTask();
  };

  const dataOnauth = (user) => {
    setTelegramId(user.id);
    setTelegramId(user.id);
    setRawTelegram(JSON.stringify(user));
    localStorage.setItem("telegramId", user.id);
    localStorage.setItem("raw", JSON.stringify(user));
  };

  return (
    <>
      <ToastContainer position="top-center">
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Успішно</strong>
          </Toast.Header>
          <Toast.Body>Заявка створена. Згодом товар буде додано!</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="sign-in__wrapper">
        <div className="sign-in__backdrop"></div>
        <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
          {telegramId ? (
            <>
              <div className="h4 mb-2 text-center">Додавання товару</div>

              {/* ALert */}

              <Form.Group className="mb-2" controlId="name">
                <Form.Label>Назва товару</Form.Label>
                <Form.Control
                  type="text"
                  value={inputUsername}
                  onChange={(e) => setInputUsername(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="buyPrice">
                <Form.Label>Ціна закупки в грн</Form.Label>
                <Form.Control
                  type="text"
                  value={inputBuy}
                  onChange={(e) => setInputBuy(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-2" controlId="sellPrice">
                <Form.Label>Ціна продажу в грн</Form.Label>
                <Form.Control
                  type="text"
                  value={inputSell}
                  onChange={(e) => setInputSell(e.target.value)}
                  required
                />
              </Form.Group>

              {!loading ? (
                <Button className="w-100" variant="primary" type="submit">
                  Відправити
                </Button>
              ) : (
                <Button
                  className="w-100"
                  variant="primary"
                  type="submit"
                  disabled
                >
                  Відправка...
                </Button>
              )}
            </>
          ) : (
            <>
              <div className="h4 mb-2 text-center">Вхід</div>
              <p>Увійдіть через телеграм, що ми мали змогу відправити вам ID</p>
              <TelegramLoginButton
                botName="easyland_products_bot"
                dataOnauth={dataOnauth}
                style={{ width: "100%" }}
              />
            </>
          )}
        </Form>
        {/* Footer */}
      </div>
    </>
  );
};

export default App;
